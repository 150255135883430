import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconHelperService {
  public constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  public registerIcons(): void {
    Object.values(ICONS).forEach((value, index) => {
      this.addSvgIcon(Object.keys(ICONS)[index], value);
    });
  }

  public addSvgIcon(name: string, url: string) {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(url));
  }
}

export const ICONS: IconMap = {
  PLUS: 'assets/icons/plus.svg',
  MINUS: 'assets/icons/minus.svg',
  HELP: 'assets/icons/help.svg',
  KEBAB: 'assets/icons/kebab.svg',
  CANCEL: 'assets/icons/cancel.svg',
  CIRCLE_PLUS: 'assets/icons/circle-plus.svg',
  AIRPLANE: 'assets/icons/airplane.svg',
  SPEAKER: 'assets/icons/speaker.svg',
  SPEAKER_ON: 'assets/icons/speaker_on.svg',
  SPEAKER_OFF: 'assets/icons/speaker_off.svg',
  START_RECORDING: 'assets/icons/recording-start.svg',
  START_RECORDING_2: 'assets/icons/recording-start-2.svg',
  START_RECORDING_3: 'assets/icons/recording-start-3.svg',
  STOP_RECORDING: 'assets/icons/recording-stop.svg',
  MAXIMIZE: 'assets/icons/maximize.svg',
  MINIMIZE: 'assets/icons/minimize.svg',
  MUSIC_ARTIST: 'assets/images/custom-icons/music_artist.svg',
  MUSIC_NOTE_ON: 'assets/icons/music_note_on.svg',
  MUSIC_NOTE_OFF: 'assets/icons/music_note_off.svg',
  JOIN_PRESENTATION: 'assets/images/custom-icons/join-presentation.svg',
  ZOOM_IN: 'assets/images/custom-icons/zoom-in.svg',
  ZOOM_OUT: 'assets/images/custom-icons/zoom-out.svg',
  GROUP_SHARE_ICON: 'assets/icons/group_share_icon.svg',
  SHARE_SCREEN: 'assets/images/custom-icons/share-screen.svg',
  STOP_SHARE_SCREEN: 'assets/images/custom-icons/stop-share-screen.svg',
  FULLSCREEN: 'assets/icons/fullscreen.svg',
  ARROW_BACK: 'assets/icons/arrow_back_ios_new.svg',
  ARROW_BACK_SETTINGS: 'assets/icons/arrow-back-settings.svg',
  PRESENT_TO_ALL: 'assets/icons/present_to_all.svg',
  PRESENT_TO_ALL_FILLED: 'assets/icons/present_to_all_filled.svg',
  FIND: 'assets/icons/find.svg',
  TELEPORT: 'assets/icons/teleport.svg',
  CHAT: 'assets/icons/chat.svg',
  FIND_DISABLED: 'assets/icons/find-disabled.svg',
  TELEPORT_DISABLED: 'assets/icons/teleport-disabled.svg',
  CHAT_DISABLED: 'assets/icons/chat-disabled.svg',
  WARNING: 'assets/icons/warning.svg',
  FIND_OCEAN: 'assets/icons/find-ocean.svg',
  TELEPORT_OCEAN: 'assets/icons/teleport-ocean.svg',
  CHAT_OCEAN: 'assets/icons/chat-ocean.svg',
  SWIRL_ARROW: 'assets/icons/swirl-arrow.svg',
  FAVORITE_STAR: 'assets/icons/favorite_star.svg',
  FAVORITE_STAR_FILL: 'assets/icons/favorite_star_fill.svg',
  FAVORITE_STAR_FILL_SUCCESS: 'assets/icons/favorite_star_fill_success.svg',
  MUSIC_OFF: 'assets/images/music_mute.svg',
  PLAY_MUSIC: 'assets/images/play_arrow.svg',
  STOP_MUSIC: 'assets/images/stop_circle.svg',
  ROUNDED_ARROW_RIGHT: 'assets/icons/rounded_arrow_right.svg',
  ROUNDED_ARROW_RIGHT_NAVY: 'assets/icons/rounded_arrow_right_navy.svg',
  ENTER_ICON: 'assets/icons/enter_icon.svg',
  APPROVE_ICON: 'assets/icons/approve_icon.svg',
  DOWNLOAD_ICON: 'assets/icons/download.svg',
  INFO_OUTLINE: 'assets/icons/info-outline.svg',
  ARROW_CIRCLE_RIGHT: 'assets/icons/arrow-circle-right.svg',
  INFO_OUTLINE_NAVY: 'assets/icons/info-outline-navy.svg',
  FILE_UPLOADED: 'assets/icons/file-uploaded-icon.svg',
  FILE_DRAFT: 'assets/icons/file-draft.svg',
  IMAGE_UPLOADED: 'assets/icons/uploaded-image-icon.svg',
  ADD_EMOJI: 'assets/icons/add-emoji-icon.svg',
  ADD_EMOJI_DISABLED: 'assets/icons/add-emoji-disabled.svg',
  ADD_EMOJI_ENABLED: 'assets/icons/add-emoji-enabled.svg',
  EMBED_VIDEO: 'assets/icons/embed-video.svg',
  FRAME_CONFIG: 'assets/icons/frame-config.svg',
  NEW_TAB_ICON: 'assets/icons/new-tab.svg',
  NETWORK_PEOPLE_ENGAGED: 'assets/icons/engaged-people.svg',
  NETWORK_COMPANIES_ENGAGED: 'assets/icons/engaged-company.svg',
  RESTORE_SCREENSHARE: 'assets/icons/restore-screenshare.svg',
  DIAMOND_ACCESS: 'assets/icons/diamond-access.svg',
  CHAT_MOBILE: 'assets/icons/chat-mobile.svg',
  MENU_MOBILE: 'assets/icons/menu-mobile.svg',
};

type IconMap = Record<string, string>;
